<template>
  <section id="analytics">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showAnalytics" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="2" md="2">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalOrdersAmount | formatCurrencyNumber }}
                </h2>
                <span>Total Order Amount</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="2" md="2"></b-col>
        <b-col xl="2" md="2"></b-col>
        <b-col xl="2" md="2">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalOrdersCount | formatNumber }}
                </h2>
                <span>Total Order Count</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="2" md="2"></b-col>
        <b-col xl="2" md="2"></b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders amount
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders-amount" />
              </h4>
              <b-popover target="popover-orders-amount" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersAmountChart" :options="ordersAmountChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders count
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders-count" />
              </h4>
              <b-popover target="popover-orders-count" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersCountChart" :options="ordersCountChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="4">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders by support employee
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-orders-support-employee" />
              </h4>
              <b-popover target="popover-orders-support-employee" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersSupportChart" :options="ordersSupportChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="8">
          <b-row>
            <b-col xl="10" md="10">
              <b-card no-body>
                <b-card-header>
                  <h4 class="mb-0">
                    Avg. order value
                    <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                      id="popover-avg-order-value" />
                  </h4>
                  <b-popover target="popover-avg-order-value" triggers="hover" placement="bottom">
                    <span>No data</span>
                  </b-popover>
                </b-card-header>
                <ECharts ref="avgOrderValueChart" :options="avgOrderValueChart" style="width: 100%; height: 400px;" />
              </b-card>
            </b-col>
            <b-col xl="2" md="2">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ avgOrderValue | formatCurrencyNumber }}
                    </h2>
                    <span>Avg. Order Value</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="8">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover by platform
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-turnover-platform" />
              </h4>
              <b-popover target="popover-turnover-platform" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="turnoverByPlatformChart" :options="turnoverByPlatformChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="4">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders by platform
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-orders-platform" />
              </h4>
              <b-popover target="popover-orders-platform" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersPlatformChart" :options="ordersPlatformChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover all countries vs Deutschland
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-turnover-all-countries-deutschland" />
              </h4>
              <b-popover target="popover-turnover-all-countries-deutschland" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="turnoverAllVsDeChart" :options="turnoverAllVsDeChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover by countries
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-turnover-countries" />
              </h4>
              <b-popover target="popover-turnover-countries" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="turnoverByCountryChart" :options="turnoverByCountryChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="8">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Top countries
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-top-countries" />
              </h4>
              <b-popover target="popover-top-countries" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="topCountriesChart" :options="topCountriesChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="4">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Top customer groups
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-top-customer-groups" />
              </h4>
              <b-popover target="popover-top-customer-groups" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="topCustomerGroupsChart" :options="topCustomerGroupsChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="2" md="2">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalAmountGermany | formatCurrencyNumber }}
                </h2>
                <span>Germany</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="2" md="2"></b-col>
        <b-col xl="2" md="2">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalAmountOutsideEU | formatCurrencyNumber }}
                </h2>
                <span>Outside EU</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="2" md="2"></b-col>
        <b-col xl="2" md="2">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalAmountEU | formatCurrencyNumber }}
                </h2>
                <span>EU (except Germany)</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover by country group
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-turnover-country-group" />
              </h4>
              <b-popover target="popover-turnover-country-group" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="turnoverByCountryGroupChart" :options="turnoverByCountryGroupChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Special vs standard items
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-special-standard-items" />
              </h4>
              <b-popover target="popover-special-standard-items" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="specialStandardItemsChart" :options="specialStandardItemsChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BPopover,
  BAvatar,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BAvatar,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      showAnalytics: true,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      totalOrdersAmount: 0.0,
      totalOrdersCount: 0.0,
      avgOrderValue: 0.0,
      totalAmountGermany: 0.0,
      totalAmountOutsideEU: 0.0,
      totalAmountEU: 0.0,
      queryParams: {},
      ordersAmountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersCountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersPlatformChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['0%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      ordersSupportChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['0%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      avgOrderValueChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      turnoverByPlatformChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      turnoverAllVsDeChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      turnoverByCountryChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      topCountriesChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      topCustomerGroupsChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['0%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      turnoverByCountryGroupChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      specialStandardItemsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];

    try {
      await this.getAnalyticsData();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    formatMonthYear(month, year) {
      return `${month}.${year}`
    },
    transformDataByCountryGroup(arr) {
      const resultMap = new Map();

      arr.forEach(item => {
        const key = this.formatMonthYear(item.month, item.year);
        if (!resultMap.has(key)) {
          resultMap.set(key, { month: item.month, year: item.year, 'Outside EU': 0, 'Germany': 0, 'EU (except Germany)': 0 });
        }
        resultMap.get(key)[item.country_group] = item.turnover.toLocaleString('de-DE');
      });

      return Array.from(resultMap.entries()).map(([month_year, value]) => ({
        month_year,
        ...value
      }));
    },
    updateData(data) {
      this.ordersPlatformChart.series[0].data = data[0].data.results.map(item => {
        return {
          value: item.turnover,
          name: item.platform,
        };
      });

      this.ordersSupportChart.series[0].data = data[1].data.results.map(item => {
        return {
          value: item.turnover,
          name: item.employee,
        };
      });

      const avgOrderValueList = data[2].data.results.map(item => item.avg_order_value);
      this.avgOrderValueChart.xAxis.data = data[2].data.results.map(item => item.month_year);
      this.avgOrderValueChart.series = {
        name: "Avg. order value",
        type: 'line',
        label: {
          show: true,
          position: 'top',
          distance: 5,
          align: 'left',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
        },
        data: avgOrderValueList,
      };
      let sumAvgOrderValues = avgOrderValueList.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      this.avgOrderValue = sumAvgOrderValues / avgOrderValueList.length;

      const turnoverMonthYearLabels = data[3].data.results.map(item => item.month_year);
      this.turnoverAllVsDeChart.xAxis.data = turnoverMonthYearLabels;
      this.turnoverAllVsDeChart.series[0] = {
        name: "All",
        type: 'line',
        label: {
          show: true,
          position: 'top',
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
        },
        data: data[3].data.results.map(item => item.turnover_all),
      };
      this.turnoverAllVsDeChart.series[1] = {
        name: "Deutschland",
        type: 'line',
        label: {
          show: true,
          position: 'top',
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
        },
        data: data[3].data.results.map(item => item.turnover_de),
      };

      // Turnover by countries
      const groupedByCountry = data[4].data.results.reduce((acc, curr) => {
        const key = curr.country
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
      }, {})

      let turnoverByCountryDatasets = [];
      for (const key in groupedByCountry) {
        let monthYearValues = groupedByCountry[key].map(item => item.month_year);
        let turnoverValues = groupedByCountry[key].map(item => item.turnover);
        let turnoverData = [];
        for (var i = 0; i < turnoverMonthYearLabels.length; i++) {
          let foundValue = false;
          for (var j = 0; j < monthYearValues.length; j++) {
            if (turnoverMonthYearLabels[i] == monthYearValues[j]) {
              turnoverData.push(turnoverValues[j]);
              foundValue = true;
              break;
            }
          }
          if (!foundValue) {
            turnoverData.push(0.0);
          }
        }
        turnoverByCountryDatasets.push({
          name: key,
          type: 'line',
          data: turnoverData,
        });
      }

      this.turnoverByCountryChart.xAxis.data = turnoverMonthYearLabels;
      this.turnoverByCountryChart.series = turnoverByCountryDatasets;

      // Turnover by platforms
      const groupedByPlatform = data[5].data.results.reduce((acc, curr) => {
        const key = curr.platform
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
      }, {});

      let newGroupedByPlatform = {};
      newGroupedByPlatform["JTL-Wawi"] = groupedByPlatform["JTL-Wawi"];
      newGroupedByPlatform["Onlineshop"] = groupedByPlatform["Onlineshop"];
      newGroupedByPlatform["Amazon"] = groupedByPlatform["Amazon"];

      let turnoverByPlatformDatasets = [];
      for (const key in newGroupedByPlatform) {
        let monthYearValues = newGroupedByPlatform[key].map(item => item.month + "." + item.year);
        let turnoverValues = newGroupedByPlatform[key].map(item => item.turnover);
        let turnoverData = [];
        for (var i = 0; i < turnoverMonthYearLabels.length; i++) {
          let foundValue = false;
          for (var j = 0; j < monthYearValues.length; j++) {
            if (turnoverMonthYearLabels[i] == monthYearValues[j]) {
              turnoverData.push(turnoverValues[j]);
              foundValue = true;
              break;
            }
          }
          if (!foundValue) {
            turnoverData.push(0.0);
          }
        }
        turnoverByPlatformDatasets.push({
          name: key,
          type: 'line',
          label: {
            show: true,
            position: 'top',
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: turnoverData,
        });
      }

      this.turnoverByPlatformChart.xAxis.data = turnoverMonthYearLabels;
      this.turnoverByPlatformChart.series = turnoverByPlatformDatasets;

      this.topCountriesChart.xAxis.data = data[6].data.results.map(item => item.country);
      this.topCountriesChart.series = {
        name: "Turnover",
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[6].data.results.map(item => item.turnover),
      };

      this.topCustomerGroupsChart.series[0].data = data[7].data.results.map(item => {
        return {
          value: item.turnover,
          name: item.customer_group,
        };
      });

      const ordersMonths = data[8].data.results.map(item => item.month + "." + item.year);
      const ordersAmountList = data[8].data.results.map(item => item.turnover);
      const ordersCountList = data[8].data.results.map(item => item.orders_count);

      this.ordersAmountChart.xAxis.data = ordersMonths;
      this.ordersAmountChart.series = {
        name: "Amount",
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: ordersAmountList,
      };

      this.ordersCountChart.xAxis.data = ordersMonths;
      this.ordersCountChart.series = {
        name: "Count",
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: ordersCountList,
      };

      this.totalOrdersAmount = ordersAmountList.reduce((a, b) => (a + b));
      this.totalOrdersCount = ordersCountList.reduce((a, b) => (a + b));

      // Data by country groups
      const dataTurnoverByCountryGroup = data[9].data.results;
      const turnoversByCountryGroup = {};

      dataTurnoverByCountryGroup.forEach(item => {
        // Check if the country group already exists in the turnoversByCountryGroup object
        if (!turnoversByCountryGroup[item.country_group]) {
          turnoversByCountryGroup[item.country_group] = []; // Initialize the array if it doesn't exist
        }
        // Push the turnover into the appropriate array
        turnoversByCountryGroup[item.country_group].push(item.turnover);
      });
      const euTurnovers = turnoversByCountryGroup["EU (except Germany)"];
      const outsideEuTurnovers = turnoversByCountryGroup["Outside EU"];
      const germanyTurnovers = turnoversByCountryGroup["Germany"];

      this.totalAmountGermany = germanyTurnovers.reduce((a, b) => (a + b));
      this.totalAmountOutsideEU = outsideEuTurnovers.reduce((a, b) => (a + b));
      this.totalAmountEU = euTurnovers.reduce((a, b) => (a + b));

      let countryGroups = dataTurnoverByCountryGroup.map(item => item.country_group);
      let monthsYears = dataTurnoverByCountryGroup.map(item => item.month_year);
      countryGroups = [...new Set(countryGroups)];
      monthsYears = [...new Set(monthsYears)];

      const turnoverByCountryGroupChartDatasets = [];
      let findValues;
      for (var i = 0; i < countryGroups.length; i++) {
        const turnoverByCountryGroup = [];

        for (var j = 0; j < monthsYears.length; j++) {
          findValues = false;
          for (var k = 0; k < dataTurnoverByCountryGroup.length; k++) {
            if (countryGroups[i] === dataTurnoverByCountryGroup[k].country_group && monthsYears[j] === dataTurnoverByCountryGroup[k].month_year) {
              turnoverByCountryGroup.push(dataTurnoverByCountryGroup[k].turnover);
              findValues = true;
              dataTurnoverByCountryGroup.splice(k, 1);
              break;
            }
          }
          if (!findValues) {
            turnoverByCountryGroup.push(0.0);
          }
        }

        turnoverByCountryGroupChartDatasets.push({
          name: countryGroups[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: turnoverByCountryGroup,
        });
      }

      this.turnoverByCountryGroupChart.xAxis.data = monthsYears;
      this.turnoverByCountryGroupChart.series = turnoverByCountryGroupChartDatasets;

      // Special vs Standard Items By Month
      const dataSpecialStandardItems = data[10].data.results;

      monthsYears = dataSpecialStandardItems.map(item => item.month_year);
      monthsYears = [...new Set(monthsYears)];

      const specialStandardItemsChartDatasets = [];
      const specialItemsValues = [];
      const standardItemsValues = [];

      for (var j = 0; j < monthsYears.length; j++) {
        findValues = false;
        for (var k = 0; k < dataSpecialStandardItems.length; k++) {
          if (monthsYears[j] === dataSpecialStandardItems[k].month_year) {
            specialItemsValues.push(dataSpecialStandardItems[k].special_items_value);
            standardItemsValues.push(dataSpecialStandardItems[k].standard_items_value);
            findValues = true;
            dataSpecialStandardItems.splice(k, 1);
            break;
          }
        }
        if (!findValues) {
          specialItemsValues.push(0.0);
          standardItemsValues.push(0.0);
        }
      }

      specialStandardItemsChartDatasets[0] = {
        name: 'Special Items',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: specialItemsValues,
      };
      specialStandardItemsChartDatasets[1] = {
        name: 'Standard Items',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: standardItemsValues,
      };

      this.specialStandardItemsChart.xAxis.data = monthsYears;
      this.specialStandardItemsChart.series = specialStandardItemsChartDatasets;
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAnalyticsData() {
      this.showAnalytics = true;

      try {
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-by-platform/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-by-supports/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/avg-order-by-month-year/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/turnover-by-date/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/turnover-by-country-and-date/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-platform-and-month/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-by-country/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/turnover-by-customer-groups/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-country-group/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/special-standard-items-by-month/`, this.queryParams),
        ]);

        this.updateData(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showAnalytics = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        await this.getAnalyticsData();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.ordersAmountChart.refresh();
          this.$refs.ordersCountChart.refresh();
          this.$refs.ordersSupportChart.refresh();
          this.$refs.avgOrderValueChart.refresh();
          this.$refs.turnoverByPlatformChart.refresh();
          this.$refs.ordersPlatformChart.refresh();
          this.$refs.turnoverAllVsDeChart.refresh();
          this.$refs.turnoverByCountryChart.refresh();
          this.$refs.topCountriesChart.refresh();
          this.$refs.topCustomerGroupsChart.refresh();
          this.$refs.turnoverByCountryGroupChart.refresh();
          this.$refs.specialStandardItemsChart.refresh();
        });
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
